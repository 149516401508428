<template>
  <div class="mt-8 mb-2">
    <div class="flex flex-col items-center">
      <div
        class="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600"
      >
        <span
          class="font-medium text-gray-600 dark:text-gray-300"
          v-if="!profilePhoto"
          >{{ initials() }}</span
        >
        <img class="w-full" v-else :src="profilePhoto" :alt="initials()" />
      </div>
      <h5 class="mb-2 text-gray-800" v-if="user">{{ user.name }}</h5>

      <p class="text-gray-600 text-xs text-center" v-if="roles?.length > 0">
        <span class="font-bold"> {{ position?.friendly_name }} </span>
        <br />
        <span v-for="role in roles" :key="role.id" class="text-orange">
          ({{ role.friendly_name }})
        </span>
      </p>

      <div class="mt-3 flex">
        <ul class="flex flex-row gap-2">
          <li
            class="max-h-[30px] min-h-[30px] h-[30px] min-w-[30px]"
            v-for="(badge) in user.badges?.slice(0, 3)"
            :key="badge.id"
          >
            <ElTooltip
              class="box-item"
              effect="dark"
              :content="getActiveBadge(badge, user.badges).content"
              placement="top"
            >
              <img
                class="max-h-[30px] min-h-[30px] h-[30px] min-w-[30px]"
                :class="{
                        'opacity-25': !getActiveBadge(
                          badge,
                          user.badges
                        ).isActive,
                      }"
                :src="badge?.image"
                :alt="badge?.name"
              />
            </ElTooltip>
          </li>
        </ul>
        <span v-if="user.badges.length > 3" class="inline-flex items-center justify-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ml-2 cursor-pointer" @click="openLeaderboard">+{{ user.badges.length - 3 }}</span>
      </div>
    </div>

    <div v-if="loadingSummaryReport || loadingAssignedContentTeamProgress">
      <SkeletonMyProgress
        :is-loading="loadingSummaryReport || loadingAssignedContentTeamProgress"
        :hasViewTeamProgressPermission="hasViewTeamProgressPermission"
      />
    </div>
    <template v-else>
      <div class="mt-8 flex items-stretch">
        <div
          class="w-full md:w-1/2 bg-gray-100 py-8 px-4 mr-2 cursor-pointer hover:bg-gray-200"
          @click="viewDetails('completed')"
        >
          <h3
            class="mb-1 text-gray-800 text-center text-3xl font-medium hover:text-gray-950"
          >
            {{ completedCourseCount }}
          </h3>
          <p class="text-sm text-center text-gray-600 hover:text-gray-950">
            Courses Completed
          </p>
        </div>
        <div
          class="w-full md:w-1/2 bg-gray-100 py-8 px-4 ml-2 cursor-pointer hover:bg-gray-200"
          @click="viewDetails('certificates')"
        >
          <h3
            class="mb-1 text-gray-800 text-center text-3xl font-medium hover:text-gray-950"
          >
            {{ certificatesCount }}
          </h3>
          <p class="text-sm text-center text-gray-600 hover:text-gray-950">
            Certificates Earned
          </p>
        </div>
      </div>
      <div class="mt-4 flex items-stretch">
        <div
          class="w-full md:w-1/2 bg-gray-100 py-8 px-4 mr-2 cursor-pointer hover:bg-gray-200"
          @click="viewDetails('in-progress')"
        >
          <h3
            class="mb-1 text-gray-800 text-center text-3xl font-medium hover:text-gray-950"
          >
            {{ inProgressCount }}
          </h3>
          <p class="text-sm text-center text-gray-600 hover:text-gray-950">
            Courses in Progress
          </p>
        </div>
        <div
          class="w-full md:w-1/2 bg-gray-100 py-8 px-4 ml-2 cursor-pointer hover:bg-gray-200"
          @click="viewDetails('assigned')"
        >
          <h3
            class="mb-1 text-gray-800 text-center text-3xl font-medium hover:text-gray-950"
          >
            {{ assignedCount }}
          </h3>
          <p class="text-sm text-center text-gray-600 hover:text-gray-950">
            Assigned to You
          </p>
        </div>
      </div>
      <div v-if="hasViewTeamProgressPermission">
        <div class="flex flex-col items-center">
          <h5 class="mt-8 mb-2 text-gray-800">Your Team’s Progress</h5>
        </div>
        <hr class="mb-6 border-t border-untitled-gray-300" />
        <div class="mt-4 flex flex-col md:flex-row gap-2 items-center">
          <ElTooltip
            :content="
              wrapTooltipContent(
                `This number represents the total number of completed assignments for your team.`
              )
            "
            effect="dark"
            :raw-content="true"
            placement="top-start"
          >
            <div
              class="w-full md:w-1/3 bg-gray-100 py-8 cursor-pointer has-tooltip hover:bg-gray-200"
              @click="goToAssignmentsCompleted()"
            >
              <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
                {{ completedAssignedContentsCount }}
              </h3>
              <p class="text-sm text-center text-gray-600">
                Completed <br />
                Assignments
              </p>
            </div>
          </ElTooltip>

          <ElTooltip
            :content="
              wrapTooltipContent(`This number represents the total number of in-progress
              assignments for your team.`)
            "
            effect="dark"
            :raw-content="true"
            placement="top-start"
          >
            <div
              class="w-full md:w-1/3 bg-gray-100 py-8 cursor-pointer has-tooltip hover:bg-gray-200"
              @click="goToAssignmentsInProgress()"
            >
              <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
                {{ inProgressAssignedContentsCount }}
              </h3>
              <p class="text-sm text-center text-gray-600">
                In Progress <br />
                Assignments
              </p>
            </div>
          </ElTooltip>

          <ElTooltip
            :content="
              wrapTooltipContent(`This number represents the total number of past-due
            assignments for your team.`)
            "
            effect="dark"
            :raw-content="true"
            placement="top-start"
          >
            <div
              class="w-full md:w-1/3 bg-gray-100 py-8 cursor-pointer has-tooltip hover:bg-gray-200"
              @click="goToAssignmentsPastOverdue()"
            >
              <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
                {{ pastDueAssignedContentsCount }}
              </h3>
              <p class="text-sm text-center text-gray-600">
                Past Due <br />
                Assignments
              </p>
            </div>
          </ElTooltip>
        </div>
      </div>
    </template>
  </div>
  <CompletedCoursesModal
    :courses="courses"
    v-model="show_completed_courses_modal"
  />
  <CourseInProgressModal
    v-model="show_in_progress_courses_modal"
    :courses="courses"
  />
  <AssignedCourseModal
    v-model="show_assigned_courses_modal"
    :courses="courses"
  />
  <CertificatesEarnedModal
    v-model="show_certificates_earned_modal"
    :courses="courses"
  />
</template>
<script>
import { ref, inject, defineComponent } from "vue";
import CompletedCoursesModal from "../my-progress/CompletedCoursesModal.vue";
import CourseInProgressModal from "../my-progress/CourseInProgressModal.vue";
import AssignedCourseModal from "../my-progress/AssignedCourseModal.vue";
import CertificatesEarnedModal from "../my-progress/CertificatesEarnedModal.vue";
import { useAuthStore } from "@/stores";
import { computed } from "@vue/reactivity";
import SkeletonMyProgress from "@/components/loaders/SkeletonMyProgress";
import Bugsnag from "@bugsnag/js";
import { router } from "@/router";
import { ElTooltip } from "element-plus";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";

export default defineComponent({
  components: {
    CompletedCoursesModal,
    CourseInProgressModal,
    AssignedCourseModal,
    CertificatesEarnedModal,
    SkeletonMyProgress,
    ElTooltip,
  },
  setup() {
    const user = ref({
      name: "Webinarinc User",
      display_picture:
        "https://webinarinc-v2-development.s3-us-west-2.amazonaws.com/default/default_profile_picture.jpg",
    });

    const roles = ref([]);
    const position = ref(null);
    const axios = inject("axios");

    const progress_type = ref(null);
    const courses = ref([]);
    const certificates = ref([]);
    const show_completed_courses_modal = ref(false);
    const show_in_progress_courses_modal = ref(false);
    const show_assigned_courses_modal = ref(false);
    const show_certificates_earned_modal = ref(false);
    const loadingSummaryReport = ref(false);
    const loadingAssignedContentTeamProgress = ref(false);

    const summary = ref({
      courses_completed_count: 0,
      courses_in_progress_count: 0,
      total_assigned_count: 0,
      total_certificates_count: 0,
    });
    const assignedContentsSummary = ref({
      completed_count: 0,
      in_progress_count: 0,
      pat_due_count: 0,
    });

    const auth = useAuthStore();
    const profilePhoto = computed(() => auth.user?.profile_picture);

    const goToAssignmentsCompleted = () => {
      router.push({
        name: "reports",
        query: {
          tab: "assignment-history",
          statuses: ["Completed"],
        },
      });
    };

    const goToAssignmentsInProgress = () => {
      router.push({
        name: "reports",
        query: { tab: "assignment-history", statuses: ["In Progress"] },
      });
    };

    const goToAssignmentsPastOverdue = () => {
      router.push({
        name: "reports",
        query: { tab: "assignment-history", statuses: ["Overdue"] },
      });
    };

    const loadProfile = async () => {
      await axios
        .get("api/my-profile", {
          params: {
            user_id: auth.user?.id,
          },
        })
        .then((response) => {
          user.value = response.data;
          roles.value = user.value.roles;
          position.value = user.value.position;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        });
    };

    const loadMyTeamProgress = async () => {
      await axios
        .get("/api/v3/reporting/assigned-histories/team-progress")
        .then(({ data }) => {
          assignedContentsSummary.value.in_progress_count =
            data.totalInProgress;
          assignedContentsSummary.value.completed_count = data.totalCompleted;
          assignedContentsSummary.value.pat_due_count = data.totalOverdue;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        })
        .finally(() => {
          loadingAssignedContentTeamProgress.value = false;
        });
    };

    function viewDetails(type) {
      progress_type.value = type;

      showModalsChecker();
    }

    const showModalsChecker = () => {
      switch (progress_type.value) {
        case "completed":
          show_completed_courses_modal.value = true;
          break;
        case "in-progress":
          show_in_progress_courses_modal.value = true;
          break;
        case "assigned":
          show_assigned_courses_modal.value = true;
          break;
        case "certificates":
          show_certificates_earned_modal.value = true;
          break;
      }
    };

    function closeDialog() {
      show_completed_courses_modal.value = false;
      show_in_progress_courses_modal.value = false;
      show_assigned_courses_modal.value = false;
      show_certificates_earned_modal.value = false;
    }

    function initials() {
      let initials = user.value.name.split(" ");

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = user.value.name.substring(0, 2);
      }

      return initials.toUpperCase();
    }

    const loadSummaryReportCount = async () => {
      loadingSummaryReport.value = true;
      await axios
        .get("api/v3/user/my-progress")
        .then((response) => {
          summary.value = response.data;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        })
        .finally(() => {
          loadingSummaryReport.value = false;
        });
    };

    const certificatesCount = computed(() => {
      return parseInt(summary.value?.total_certificates_count);
    });

    const assignedCount = computed(() => {
      return parseInt(summary.value?.total_assigned_count);
    });

    const inProgressCount = computed(() => {
      return parseInt(summary.value?.courses_in_progress_count);
    });

    const completedCourseCount = computed(() => {
      return parseInt(summary.value?.courses_completed_count);
    });

    const completedAssignedContentsCount = computed(() => {
      return parseInt(assignedContentsSummary.value?.completed_count);
    });

    const inProgressAssignedContentsCount = computed(() => {
      return parseInt(assignedContentsSummary.value?.in_progress_count);
    });

    const pastDueAssignedContentsCount = computed(() => {
      return parseInt(assignedContentsSummary.value?.pat_due_count);
    });

    const authUser = useAuthStore();

    const hasViewTeamProgressPermission = computed(() => {
      const user = authUser.$state.user;

      if (!user) {
        return false;
      }

      return user.roles.some(
        (role) =>
          role.name === "super-administrator" ||
          role.name === "account-manager" ||
          role.name === "specific-dealer-manager"
      );
    });

    loadProfile();
    loadSummaryReportCount();
    if (hasViewTeamProgressPermission.value) {
      loadMyTeamProgress();
    }

    const getActiveBadge = (badge, userBadges) => {
      const isActiveBadge =
        userBadges?.find((item) => item?.id === badge?.id) !== undefined;

      return {
        content: isActiveBadge ? badge?.description : badge?.description_inactive,
        isActive: isActiveBadge,
      };
    };

    const openLeaderboard = () => {
      router.push({ name: "Leaderboard", query: { current_user: '' } });
    };

    return {
      user,
      roles,
      position,
      loadProfile,
      viewDetails,
      show_completed_courses_modal,
      show_in_progress_courses_modal,
      show_assigned_courses_modal,
      show_certificates_earned_modal,
      progress_type,
      courses,
      certificates,
      closeDialog,
      summary,
      initials,
      loadingSummaryReport,
      loadingAssignedContentTeamProgress,
      loadSummaryReportCount,
      certificatesCount,
      assignedCount,
      inProgressCount,
      completedCourseCount,
      showModalsChecker,
      hasViewTeamProgressPermission,
      completedAssignedContentsCount,
      inProgressAssignedContentsCount,
      pastDueAssignedContentsCount,
      goToAssignmentsCompleted,
      goToAssignmentsInProgress,
      goToAssignmentsPastOverdue,
      wrapTooltipContent,
      profilePhoto,
      getActiveBadge,
      openLeaderboard,
    };
  },
});
</script>
<style scoped>
.tooltip {
  visibility: hidden;
  position: absolute;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}
</style>
